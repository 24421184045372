import { template as template_6a29fdd657d4488aa1e3b893030d52fd } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_6a29fdd657d4488aa1e3b893030d52fd(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
