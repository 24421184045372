import { template as template_813f9e4398b54bf6be4228862425d97a } from "@ember/template-compiler";
const WelcomeHeader = template_813f9e4398b54bf6be4228862425d97a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
