import { template as template_d9b7fdb88ee84aa4a18097443c316e43 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d9b7fdb88ee84aa4a18097443c316e43(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
